import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import logo from "../images/mighty-lab-acorn.png"
import Nav from "./nav"
import classNames from "classnames"

import headerStyles from "./header.module.scss"

const Header = ({ siteTitle, location }) => {
  const [isOpen, setIsOpen] = useState(null)
  const myRef = useRef({
    firstFocusableEl: null,
    lastFocusableEl: null,
    focusedElBeforeOpen: null,
    location: null,
  })
  useEffect(() => {
    window.addEventListener("resize", handleResize)
    // handle setup for accessibility
    document.addEventListener("keydown", keyHandler, false)
    const focusableElsRaw = myRef.current.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    )
    const focusableEls = Array.prototype.slice.call(focusableElsRaw)
    myRef.current.firstFocusableEl = focusableEls[0]
    myRef.current.lastFocusableEl = focusableEls[focusableEls.length - 1]
    // make sure dialog is closed on route change
    if (!myRef.current.location) myRef.current.location = location
    else if (myRef.current.location !== location) {
      if (isOpen) toggleState({ status: false })
      myRef.current.location = location
    }
    // cleanup
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
      document.removeEventListener("keydown", keyHandler)
    }
  })

  const toggleState = ({ props }) => {
    let status
    if (props) status = props.status
    else status = !isOpen
    setIsOpen(status)
    // dialog is opening
    if (status) {
      document.body.classList.add("nav-open")
      myRef.current.focusedElBeforeOpen = document.activeElement
    } else {
      document.body.classList.remove("nav-open")
      myRef.current.focusedElBeforeOpen.focus()
    }
  }
  const handleResize = () => {
    // the nav should never be open while browser is being resized
    if (isOpen) toggleState({ status: false })
  }
  const keyHandler = e => {
    const KEY_TAB = 9
    const KEY_ESC = 27

    switch (e.keyCode) {
      case KEY_TAB:
        if (isOpen) {
          // forward tab
          if (!e.shiftKey) {
            if (document.activeElement === myRef.current.lastFocusableEl) {
              e.preventDefault()
              myRef.current.firstFocusableEl.focus()
            }
          }
          // backward tab
          else {
            if (document.activeElement === myRef.current.firstFocusableEl) {
              e.preventDefault()
              myRef.current.lastFocusableEl.focus()
            }
          }
        }
        break
      case KEY_ESC:
        if (isOpen) toggleState({ status: false })
        break
      default:
        break
    } // end switch
  }
  return (
    <header
      ref={myRef}
      className={classNames({
        [headerStyles.header]: true,
        [headerStyles.active]: isOpen,
      })}
    >
      <div className={headerStyles.logo}>
        <Link to="/">
          <img src={logo} alt={siteTitle} />
        </Link>
      </div>
      <button
        className={headerStyles.toggle}
        onClick={toggleState}
        aria-expanded={isOpen}
        aria-label="Open the menu"
      >
        <div aria-hidden="true"></div>
        <span data-close-label="close">menu</span>
      </button>
      <Nav />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
