import React from "react"

import './footer.module.scss'

const Footer = () => (
    <footer>
        © {new Date().getFullYear()} Mighty Lab
    </footer>
)

export default Footer
