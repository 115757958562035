import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const Nav = () => (
  <StaticQuery
    query={graphql`
      query {
        strapiNavigation {
          pages {
            id
            title
            slug
          }
        }
      }
    `}
    render={data => (
      <nav>
        <ul>
          {data.strapiNavigation.pages.map(page => (
            <li key={page.id}>
              <Link to={`/${page.slug}`}>{page.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
    )}
  />
)

export default Nav
